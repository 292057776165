import {SEOModel} from "./seo";
import {DateModel} from "./date-model";

export class CookiesPageModel {
    constructor(
        public readonly title: string | null,
        public readonly content: string | null,
        public readonly locale: string | null,
        public readonly updatedAt: DateModel,
        public readonly updatedAtLabel: string | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new CookiesPageModel(
            json['title'],
            json['content'],
            json['locale'],
            new DateModel(json['updatedAt']),
            json['updatedAtLabel'],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        )
    }
}
