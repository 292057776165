import {ImageModel} from "./image";

export class PartnerLink {
    constructor(
        public readonly label: string | null,
        public readonly partner: PartnerModel | null,
    ) {}

    static fromJSON(json: any) {
        return new PartnerLink(
            json['label'],
            json['partner'] ? PartnerModel.fromJSON(json['partner']) : null,
        );
    }
}

export class PartnerModel {
    constructor(
        public readonly name: string | null,
        public readonly logo: ImageModel| null,
    ) {}

    static fromJSON(json: any) {
        return new PartnerModel(
            json['name'],
            json['logo'] ? ImageModel.fromJSON(json['logo']) : null,
        );
    }
}
