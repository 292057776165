import {IPrivacyPolicyState, PrivacyPolicyAction, PrivacyPolicyActionTypes} from "../types/privacy-policy.type";
import {PrivacyPolicyPageModel} from "../models/privacy-policy-page";

const initialState: IPrivacyPolicyState = {
  data: PrivacyPolicyPageModel.fromJSON({}),
}

export const privacyPolicyReducer = (state: IPrivacyPolicyState = initialState, action: PrivacyPolicyAction): IPrivacyPolicyState => {
  switch (action.type) {
    case PrivacyPolicyActionTypes.PRIVACY_POLICY_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
