import {IRegisterIEState, RegisterIEAction, RegisterIEActionTypes} from "../types/register-ie.type";
import {RegisterPage} from "../models/register-page";

const initialState: IRegisterIEState = {
  data: RegisterPage,
}

export const registerIE = (state: IRegisterIEState = initialState, action: RegisterIEAction): IRegisterIEState => {
  switch (action.type) {
    case RegisterIEActionTypes.REGISTER_IE_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
