import {Link} from "./register-page";
import {SEOModel} from "./seo";
import {PartnersPageCta} from "./partners-page";
import {ImageModel} from "./image";

export class BoardModel {
    constructor(
        public readonly description: string | null,
        public readonly name: string | null,
        public readonly image: ImageModel| null,
        public readonly icon: ImageModel | null,
) {}

    static fromJSON(json: any) {
        return new BoardModel(
            json['description'],
            json['name'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
            json['icon'] && json['icon']['icon'] ? ImageModel.fromJSON(json['icon']['icon']) : null,
        );
    }
}

export class BoardPageModel {
    constructor(
        public readonly title: string | null,
        public readonly ctaCard: PartnersPageCta | null,
        public readonly boardTitle: string | null,
        public readonly board: BoardModel | null,
        public readonly boardMembersTitle: string | null,
        public readonly boardList: BoardModel[] | null,
        public readonly links: Link[] | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new BoardPageModel(
            json['title'],
            json['ctaCard'] ? PartnersPageCta.fromJSON(json['ctaCard']) : null,
            json['boardTitle'],
            json['board'] ? BoardModel.fromJSON(json['board']) : null,
            json['boardMembersTitle'],
            json['boardList'] ? json['boardList'].map((link: any) => BoardModel.fromJSON(link)) : [],
            json['links'] ? json['links'].map((link: any) => Link.fromJSON(link)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        );
    }
}