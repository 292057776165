import {WorksPageModel} from "../models/works-page";

export interface IWorks {
  id?: number;
  title: string;
}

export interface IWorksState {
  data: WorksPageModel,
}

export enum WorksActionTypes {
  WORKS_FETCH = 'WORKS_FETCH',
  WORKS_FAILED = 'WORKS_FAILED',
}

interface WorksFetch {
  type: WorksActionTypes.WORKS_FETCH,
  payload: any
}

interface WorksFailed {
  type: WorksActionTypes.WORKS_FAILED,
  payload: any
}

export type WorksAction = WorksFetch | WorksFailed

