import {ImageModel} from "./image";
import {DateModel} from "./date-model";

export type MetaTag = {
    name: string,
    content: string | null | undefined
}

export class MetaSocialModel {
    constructor(
        public readonly socialNetwork: string | null,
        public readonly title: string | null,
        public readonly description: string | null,
        public readonly image: ImageModel | null,
    ) {
    }

    static fromJSON(json: any) {
        return new MetaSocialModel(
            json['socialNetwork'],
            json['title'],
            json['description'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
        )
    }
}


export class SEOModel {
    constructor(
        public readonly metaTitle: string | null,
        public readonly metaDescription: string | null,
        public readonly keywords: string | null,
        public readonly metaRobots: string | null,
        public readonly structuredData: JSON | null,
        public readonly metaViewport: string | null,
        public readonly canonicalURL: string | null,
        public readonly openGraph: OpenGraphModel | null,
        public readonly formattedTitle: string | null,
    ) {}

    get ogTitle(): string | null {
        return this.openGraph?.title || this.formattedTitle || this.metaTitle;
    }

    get ogDescription(): string | null {
        return this.openGraph?.description || this.metaDescription;
    }

    get ogUrl(): string | null {
        return this.openGraph?.url || this.canonicalURL;
    }

    get ogImage(): ImageModel | null {
        return this.openGraph?.image || null;
    }

    get ogType(): string | null {
        return this.openGraph?.type || null;
    }

    static fromJSON(json: any) {
        return new SEOModel(
            json['metaTitle'],
            json['metaDescription'],
            json['keywords'],
            json['metaRobots'],
            json['structuredData'],
            json['metaViewport'],
            json['canonicalURL'],
            json['openGraph'] ? OpenGraphModel.fromJSON(json['openGraph']) : null,
            json['formattedTitle'],
        )
    }
}

export class OpenGraphModel {
    constructor(
        public readonly title: string | null,
        public readonly type: string | null,
        public readonly url: string | null,
        public readonly description: string | null,
        public readonly articlePublishedTime: DateModel | null,
        public readonly articleModifiedTime: DateModel | null,
        public readonly articleExpirationTime: DateModel | null,
        public readonly articleAuthor: string | null,
        public readonly articleSection: string | null,
        public readonly articleTag: string | null,
        public readonly image: ImageModel | null,
    ) {}

    static fromJSON(json: any) {
        return new OpenGraphModel(
            json['title'],
            json['type'],
            json['url'],
            json['description'],
            json['articlePublishedTime'] ? new DateModel(json['articlePublishedTime']) : null,
            json['articleModifiedTime'] ? new DateModel(json['articleModifiedTime'])  : null,
            json['articleExpirationTime'] ? new DateModel(json['articleExpirationTime']) : null,
            json['articleAuthor'],
            json['articleSection'],
            json['articleTag'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
        )
    }
}
