import {BoardPageModel} from "../models/board-page";

export interface IBoardState {
  data: BoardPageModel,
}

export enum BoardActionTypes {
  BOARD_FETCH = 'BOARD_FETCH',
  BOARD_FAILED = 'BOARD_FAILED',
}

interface BoardFetch {
  type: BoardActionTypes.BOARD_FETCH,
  payload: any
}

interface BoardFailed {
  type: BoardActionTypes.BOARD_FAILED,
  payload: any
}

export type BoardAction = BoardFetch | BoardFailed

