import {IWorksState, WorksAction, WorksActionTypes} from "../types/works.type";
import {WorksPageModel, WorksPageWork} from "../models/works-page";

const initialState: IWorksState = {
  data: WorksPageModel.fromJSON({}),
}

export const worksReducer = (state: IWorksState = initialState, action: WorksAction): IWorksState => {
  switch (action.type) {
    case WorksActionTypes.WORKS_FETCH: {
      let workTypes: any = [];

      action.payload.works.map((work: WorksPageWork) => {
        workTypes.push(work.title)
      })

      return { ...state, data: {...action.payload, workTypes}}
    }
    default:
      return state
  }
}
