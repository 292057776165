import {IPartnersState, PartnersAction, PartnersActionTypes} from "../types/partners.type";
import {PartnersPageModel} from "../models/partners-page";

const initialState: IPartnersState = {
  data: PartnersPageModel.fromJSON({}),
}

export const partnersReducer = (state: IPartnersState = initialState, action: PartnersAction): IPartnersState => {
  switch (action.type) {
    case PartnersActionTypes.PARTNERS_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
