import {TranscarpathiaAction, TranscarpathiaActionTypes, ITranscarpathiaState} from "../types/transcarpathia.type";
import {TranscarpathiaPageModel} from "../models/transcarpathia-page";

const initialState: ITranscarpathiaState = {
  data: TranscarpathiaPageModel.fromJSON({}),
}

export const transcarpathiaReducer = (state: ITranscarpathiaState = initialState, action: TranscarpathiaAction): ITranscarpathiaState => {
  switch (action.type) {
    case TranscarpathiaActionTypes.TRANSCARPATHIA_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
