import {PartnerLink, PartnerModel} from "./partner";
import {CtaCard} from "./cta";
import {Link} from "./register-page";
import {SEOModel} from "./seo";

export class PartnersPageCta {
    constructor(
        public readonly title: string | null,
        public readonly cardLeft: CtaCard | null,
        public readonly cardRight: CtaCard | null,
    ) {}

    static fromJSON(json: any) {
        return new PartnersPageCta(
            json['title'],
            json['cardLeft'] ? CtaCard.fromJSON(json['cardLeft']) : null,
            json['cardRight'] ? CtaCard.fromJSON(json['cardRight']) : null,
        );
    }
}

export class PartnersGrid {
    constructor(
        public readonly label: string | null,
        public readonly partners: PartnerModel[] | null,
    ) {}

    static fromJSON(json: any) {
        return new PartnersGrid(
            json['label'],
            json['partners'] ? json['partners'].map((partner: any) => PartnerModel.fromJSON(partner)) : [],
        );
    }
}

export class FilteredPartners {
    constructor(
        public readonly title: string | null,
        public readonly partners: PartnerLink[] | null,
    ) {}

    static fromJSON(json: any) {
        return new FilteredPartners(
            json['title'],
            json['partners'] ? json['partners'].map((partner: any) => PartnerLink.fromJSON(partner)) : [],
        );
    }
}

export class PartnersPageModel {
    constructor(
        public readonly title: string | null,
        public readonly filteredPartners: FilteredPartners[] | null,
        public readonly ctaCard: PartnersPageCta | null,
        public readonly links: Link[] | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new PartnersPageModel(
            json['title'],
            json['filteredPartners'] ? json['filteredPartners'].map((partner: any) => FilteredPartners.fromJSON(partner)) : [],
            json['ctaCard'] ? PartnersPageCta.fromJSON(json['ctaCard']) : null,
            json['links'] ? json['links'].map((link: any) => Link.fromJSON(link)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        );
    }
}
