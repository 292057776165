import {configureStore} from '@reduxjs/toolkit';
import {rootReducer} from './reducers';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat([]),
  devTools: true,
});

export default store;