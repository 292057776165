
export type Locale = {
    default?: boolean
    path: string
    label: string
    locale: string
    code: string
};

export const UA_LOCALE = {
    default: true,
    path: ``,
    label: `Ukrainian`,
    locale: `uk-ua`,
    code: 'ua'
};

export const EN_LOCALE = {
    path: `en`,
    label: `English`,
    locale: `en-ua`,
    code: 'en'
};

export default [UA_LOCALE, EN_LOCALE];
