import {HomePageModel} from "../models/home-page";

export interface IHome {
  id?: number;
  images?: any;
  title: string;
}

export interface IHomeState {
  data: HomePageModel,
  loaded: boolean
}

export enum HomeActionTypes {
  HOME_FETCH = 'HOME_FETCH',
  HOME_FAILED = 'HOME_FAILED',
}

interface HomeFetch {
  type: HomeActionTypes.HOME_FETCH,
  payload: HomePageModel
}

interface HomeFailed {
  type: HomeActionTypes.HOME_FAILED,
  payload: any
}

export type HomeAction = HomeFetch | HomeFailed

