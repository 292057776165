import {SEOModel} from "./seo";


export class NotFoundPageModel {
    constructor(
        public readonly title: string | null,
        public readonly description: string | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new NotFoundPageModel(
            json['title'],
            json['description'],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        );
    }
}
