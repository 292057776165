import {RegisterPage} from "../models/register-page";

export interface IRegisterCompanies {
  id?: number;
  title: string;
}

export interface IRegisterCompaniesState {
  data: RegisterPage,
}

export enum RegisterCompaniesActionTypes {
  REGISTER_COMPANIES_FETCH = 'REGISTER_COMPANIES_FETCH',
  REGISTER_COMPANIES_FAILED = 'REGISTER_COMPANIES_FAILED',
}

interface RegisterCompaniesFetch {
  type: RegisterCompaniesActionTypes.REGISTER_COMPANIES_FETCH,
  payload: any
}

interface RegisterCompaniesFailed {
  type: RegisterCompaniesActionTypes.REGISTER_COMPANIES_FAILED,
  payload: any
}

export type RegisterCompaniesAction = RegisterCompaniesFetch | RegisterCompaniesFailed

