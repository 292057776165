import routes, {Route} from "./index";
import locales, {Locale} from "../../config/locales";
import currentLocale from "../helpers/current-locale";
import {serialize} from "../utils/serialize-query-params";

export type RouteParams = {[key: string]: any}
export type RouteQuery = {[key: string]: any}

export type RouteToConfig = {
    full?: boolean,
    locale?: Locale,
    params?: RouteParams,
    query?: RouteQuery
}

class Router {
    private static instance: Router;
    public readonly siteUrl: string;
    public readonly routes: Route[];

    constructor() {
        this.siteUrl = process.env.GATSBY_SITE_URL || 'http://locahost:8000';
        this.routes = routes;
    }

    public to(name: string, config?: RouteToConfig): string {
        const route = this.find(name);

        if (route == undefined) {
            throw Error(`Route with name ${name} not found`);
        }

        const locale: Locale = config?.locale !== undefined ? config?.locale! : currentLocale();

        return this.format(route, config?.full || false, locale, config?.params, config?.query);
    }

    private find(name: string): Route | undefined {
        return routes.find(route => route.name == name);
    }

    private format(
        route: Route,
        full: boolean = false,
        locale: Locale,
        params?: RouteParams,
        query?: RouteQuery,
    ): string {
        const localeSuffix = locale.path != '' ? `/${locale.path}/` : '/';
        let routePath = route.path != 'index' ? route.path : '';

        if (params && route.match) {
            for (const paramsKey in params) {
                const value = params[paramsKey];
                routePath = route.match.replace(`:${paramsKey}`, value);
            }
        }

        if (query) {
            routePath = `${routePath}?${serialize(query, null)}`;
        }

        if (full) {
            return `${this.siteUrl}${localeSuffix}${routePath}`;
        } else {
            return `${localeSuffix}${routePath}`
        }
    }

    public localizePath(path: string, locale: Locale, full: boolean = false) {
        const parts = path.split('/').filter((item: string) => {
            const matchLocale = locales.find(localeItem => localeItem.path === item);
            return !(item == '' || matchLocale);
        });

        if (locale.path !== '') {
            parts.unshift(`${locale.path}`);
        }

        if (full) {
            return `${this.siteUrl}/${parts.join('/')}`
        }

        return '/' + parts.join('/');
    }

    private static getCurrentLocale(): Locale {
        return currentLocale();
    }

    public static getInstance(): Router {
        if (!Router.instance) {
            Router.instance = new Router();
        }

        return Router.instance;
    }
}

export default Router.getInstance();
