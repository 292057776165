import {ContactFormModel} from "../models/contact-form";

export interface IContactFormState {
  data: ContactFormModel,
}

export enum ContactFormActionTypes {
  CONTACT_FORM_FETCH = 'CONTACT_FORM_FETCH',
  CONTACT_FORM_FAILED = 'CONTACT_FORM_FAILED',
}

interface ContactFormFetch {
  type: ContactFormActionTypes.CONTACT_FORM_FETCH,
  payload: any
}

interface ContactFormFailed {
  type: ContactFormActionTypes.CONTACT_FORM_FAILED,
  payload: any
}

export type ContactFormAction = ContactFormFetch | ContactFormFailed

