exports.components = {
  "component---src-views-about-us-page-tsx": () => import("./../../../src/views/AboutUsPage.tsx" /* webpackChunkName: "component---src-views-about-us-page-tsx" */),
  "component---src-views-board-page-tsx": () => import("./../../../src/views/BoardPage.tsx" /* webpackChunkName: "component---src-views-board-page-tsx" */),
  "component---src-views-cookies-page-tsx": () => import("./../../../src/views/CookiesPage.tsx" /* webpackChunkName: "component---src-views-cookies-page-tsx" */),
  "component---src-views-faq-page-tsx": () => import("./../../../src/views/FAQPage.tsx" /* webpackChunkName: "component---src-views-faq-page-tsx" */),
  "component---src-views-home-page-tsx": () => import("./../../../src/views/HomePage.tsx" /* webpackChunkName: "component---src-views-home-page-tsx" */),
  "component---src-views-members-page-tsx": () => import("./../../../src/views/MembersPage.tsx" /* webpackChunkName: "component---src-views-members-page-tsx" */),
  "component---src-views-news-page-tsx": () => import("./../../../src/views/NewsPage.tsx" /* webpackChunkName: "component---src-views-news-page-tsx" */),
  "component---src-views-news-post-page-tsx": () => import("./../../../src/views/NewsPostPage.tsx" /* webpackChunkName: "component---src-views-news-post-page-tsx" */),
  "component---src-views-not-found-page-tsx": () => import("./../../../src/views/NotFoundPage.tsx" /* webpackChunkName: "component---src-views-not-found-page-tsx" */),
  "component---src-views-partners-page-tsx": () => import("./../../../src/views/PartnersPage.tsx" /* webpackChunkName: "component---src-views-partners-page-tsx" */),
  "component---src-views-privacy-policy-page-tsx": () => import("./../../../src/views/PrivacyPolicyPage.tsx" /* webpackChunkName: "component---src-views-privacy-policy-page-tsx" */),
  "component---src-views-register-companies-tsx": () => import("./../../../src/views/RegisterCompanies.tsx" /* webpackChunkName: "component---src-views-register-companies-tsx" */),
  "component---src-views-register-ie-tsx": () => import("./../../../src/views/RegisterIE.tsx" /* webpackChunkName: "component---src-views-register-ie-tsx" */),
  "component---src-views-register-partners-tsx": () => import("./../../../src/views/RegisterPartners.tsx" /* webpackChunkName: "component---src-views-register-partners-tsx" */),
  "component---src-views-transcarpathia-page-tsx": () => import("./../../../src/views/TranscarpathiaPage.tsx" /* webpackChunkName: "component---src-views-transcarpathia-page-tsx" */),
  "component---src-views-works-page-tsx": () => import("./../../../src/views/WorksPage.tsx" /* webpackChunkName: "component---src-views-works-page-tsx" */)
}

