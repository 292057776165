import {FormServiceAction, FormServiceTypes, FormStatus, IFormServiceState} from "../types/form-service.types";
import _ from "lodash";
import {FormField} from "../models/form-field";

const initialState: IFormServiceState = {
    service: null,
    instance: {},
    errors: {},
    loading: false,
    formError: '',
    status: null,
}

export const formServiceReducer = (state: IFormServiceState = initialState, action: FormServiceAction): IFormServiceState => {
    switch (action.type) {
        case FormServiceTypes.FORM_SERVICE_FETCH: {
            return { ...state, service: action.payload }
        }
        case FormServiceTypes.FORM_SERVICE_SET_LOADING: {
            return { ...state, loading: action.payload.flag }
        }
        case FormServiceTypes.FORM_SERVICE_SET_VALUE: {
            const instance = _.cloneDeep(state.instance);

            instance[action.payload.key] = action.payload.value;

            return { ...state, instance }
        }
        case FormServiceTypes.FORM_SERVICE_SET_FIELD_ERROR: {
            const errors = _.cloneDeep(state.errors);

            errors[action.payload.key] = action.payload.error;

            return { ...state, errors }
        }
        case FormServiceTypes.FORM_SERVICE_SET_ERROR: {
            return { ...state, formError: action.payload.error }
        }
        case FormServiceTypes.FORM_SERVICE_CLEAR_FIELD_ERRORS: {
            const errors = _.cloneDeep(state.errors);

            for (const key in errors) {
                errors[key] = null;
            }

            return { ...state, errors }
        }
        case FormServiceTypes.FORM_SERVICE_SET_FORM_SUBMITTED: {
            return { ...state, status: FormStatus.FORM_STATUS_SUBMITTED }
        }
        case FormServiceTypes.FORM_SERVICE_CLEAR_INSTANCE: {
            const instance = _.cloneDeep(state.instance);

            for (const key in instance) {
                const field: FormField | null = state.service?.getFieldById(key) || null;
                instance[key] = field?.initialValue || '';
            }

            return { ...state, instance }
        }
        case FormServiceTypes.FORM_SERVICE_SET_STATUS: {
            return { ...state, status: action.payload.status }
        }
        default:
            return state
    }
}
