import {RegisterPage} from "../models/register-page";

export interface IRegisterPartners {
  id?: number;
  title: string;
}

export interface IRegisterPartnersState {
  data: RegisterPage,
}

export enum RegisterPartnersActionTypes {
  REGISTER_PARTNERS_FETCH = 'REGISTER_PARTNERS_FETCH',
  REGISTER_PARTNERS_FAILED = 'REGISTER_PARTNERS_FAILED',
}

interface RegisterPartnersFetch {
  type: RegisterPartnersActionTypes.REGISTER_PARTNERS_FETCH,
  payload: any
}

interface RegisterPartnersFailed {
  type: RegisterPartnersActionTypes.REGISTER_PARTNERS_FAILED,
  payload: any
}

export type RegisterPartnersAction = RegisterPartnersFetch | RegisterPartnersFailed

