import {
    IRegisterPartnersState,
    RegisterPartnersAction,
    RegisterPartnersActionTypes
} from "../types/register-partners.type";
import {RegisterPage} from "../models/register-page";

const initialState: IRegisterPartnersState = {
  data: RegisterPage,
}

export const registerPartners = (state: IRegisterPartnersState = initialState, action: RegisterPartnersAction): IRegisterPartnersState => {
  switch (action.type) {
    case RegisterPartnersActionTypes.REGISTER_PARTNERS_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
