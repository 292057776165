import {MembersPageModel} from "../models/members-page";

export interface IMembers {
  id?: number;
  title: string;
}

export interface IMembersState {
  data: MembersPageModel,
}

export enum MembersActionTypes {
  MEMBERS_FETCH = 'MEMBERS_FETCH',
  MEMBERS_FAILED = 'MEMBERS_FAILED',
}

interface MembersFetch {
  type: MembersActionTypes.MEMBERS_FETCH,
  payload: any
}

interface MembersFailed {
  type: MembersActionTypes.MEMBERS_FAILED,
  payload: any
}

export type MembersAction = MembersFetch | MembersFailed

