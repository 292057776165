import {ImageModel} from "./image";
import {ResidentLink} from "./resident";
import {Cta} from "./cta";
import {SEOModel} from "./seo";

export class HomePageModel {
    constructor(
        public readonly title: string | null,
        public readonly subTitle: string| null,
        public readonly subTitleTotal: string| null,
        public readonly images: ImageModel[] | null,
        public readonly residents: ResidentLink[] | null,
        public readonly cta: Cta | null,
        public readonly mobileImages: ImageModel[] | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new HomePageModel(
            json['title'],
            json['subTitle'],
            json['subTitleTotal'],
            json['images'] ? json['images'].map((img: any) => ImageModel.fromJSON(img)) : [],
            json['residents'] ? json['residents'].map((resident: any) => ResidentLink.fromJSON(resident)) : [],
            json['cta'] ? Cta.fromJSON(json['cta']) : null,
            json['mobileImages'] ? json['mobileImages'].map((img: any) => ImageModel.fromJSON(img)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        );
    }
}


