import {FormService} from "../models/form-service";

export interface IFormServiceState {
    service: FormService | null,
    instance: {[key: string]: any},
    errors: FormFieldValidationError,
    loading: boolean,
    formError: string,
    status: FormStatus | null,
}

export enum FormStatus {
    FORM_STATUS_ERROR = 'FORM_STATUS_ERROR',
    FORM_STATUS_LOADED = 'FORM_STATUS_LOADED',
    FORM_STATUS_SUBMITTED = 'FORM_STATUS_SUBMITTED',
}

export enum FormServiceTypes {
    FORM_SERVICE_FETCH = 'FORM_SERVICE_FETCH',
    FORM_SERVICE_FAILED = 'FORM_SERVICE_FAILED',
    FORM_SERVICE_SET_VALUE = 'FORM_SERVICE_SET_VALUE',
    FORM_SERVICE_SET_FIELD_ERROR = 'FORM_SERVICE_SET_FIELD_ERROR',
    FORM_SERVICE_SET_LOADING = 'FORM_SERVICE_SET_LOADING',
    FORM_SERVICE_SET_ERROR = 'FORM_SERVICE_SET_ERROR',
    FORM_SERVICE_CLEAR_FIELD_ERRORS = 'FORM_SERVICE_CLEAR_FIELD_ERRORS',
    FORM_SERVICE_SET_FORM_SUBMITTED = 'FORM_SERVICE_SET_FORM_SUBMITTED',
    FORM_SERVICE_CLEAR_INSTANCE = 'FORM_SERVICE_CLEAR_INSTANCE',
    FORM_SERVICE_SET_STATUS = 'FORM_SERVICE_SET_STATUS',
}

interface FormServiceFetch {
    type: FormServiceTypes.FORM_SERVICE_FETCH,
    payload: FormService
}

interface FormServiceFailed {
    type: FormServiceTypes.FORM_SERVICE_FAILED,
    payload: any
}

export interface FormServiceSetValue {
    type: FormServiceTypes.FORM_SERVICE_SET_VALUE,
    payload: {
        key: string,
        value: any
    }
}

interface FormServiceSetFieldError {
    type: FormServiceTypes.FORM_SERVICE_SET_FIELD_ERROR,
    payload: {
        key: string,
        error: string | null
    }
}

interface FormServiceSetError {
    type: FormServiceTypes.FORM_SERVICE_SET_ERROR,
    payload: {
        error: string
    }
}

interface FormServiceSetLoading {
    type: FormServiceTypes.FORM_SERVICE_SET_LOADING,
    payload: {
        flag: boolean
    }
}

interface FormServiceClearFieldErrors {
    type: FormServiceTypes.FORM_SERVICE_CLEAR_FIELD_ERRORS,
}

interface FormServiceSetFormSubmitted {
    type: FormServiceTypes.FORM_SERVICE_SET_FORM_SUBMITTED,
}

interface FormServiceClearInstance {
    type: FormServiceTypes.FORM_SERVICE_CLEAR_INSTANCE,
}

interface FormServiceSetStatus {
    type: FormServiceTypes.FORM_SERVICE_SET_STATUS,
    payload: {
        status: FormStatus
    }
}

export type FormServiceAction = FormServiceFetch
                                | FormServiceFailed
                                | FormServiceSetValue
                                | FormServiceSetFieldError
                                | FormServiceSetLoading
                                | FormServiceSetError
                                | FormServiceClearFieldErrors
                                | FormServiceSetFormSubmitted
                                | FormServiceClearInstance
                                | FormServiceSetStatus;

export type FromServiceValidationErrorSource = {
    parameter: string,
    pointer: string
}

export type FromServiceValidationError = {
    id: string,
    title: string,
    detail: string,
    source: FromServiceValidationErrorSource
};

export type FormFieldValidationError = {[key: string]: string | null};
