import {ResidentLink} from "./resident";
import {CtaCard} from "./cta";
import {Link} from "./register-page";
import {SEOModel} from "./seo";
import {InternalLink} from "./internal-link";
import {ImageModel} from "./image";

export class BenefitModel {
    constructor(
        public readonly title: string | null,
        public readonly label: string | null,
        public readonly url: InternalLink | null,
        public readonly icon: ImageModel | null,
    ) {}

    static fromJSON(json: any) {
        return new BenefitModel(
            json['title'],
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
            json['icon'] && json['icon']['icon']  ? ImageModel.fromJSON(json['icon']['icon']) : null,
        );
    }
}

export class MembersPageModel {
    constructor(
        public readonly title: string | null,
        public readonly benefitTitle: string | null,
        public readonly benefitAnchor: string | null,
        public readonly residents: ResidentLink[] | null,
        public readonly benefit: BenefitModel[] | null,
        public readonly ctaCard: CtaCard | null,
        public readonly links: Link[] | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new MembersPageModel(
            json['title'],
            json['benefitTitle'],
            json['benefitAnchor'],
            json['residents'] ? json['residents'].map((resident: any) => ResidentLink.fromJSON(resident)) : [],
            json['benefit'] ? json['benefit'].map((resident: any) => BenefitModel.fromJSON(resident)) : [],
            json['ctaCard'] ? CtaCard.fromJSON(json['ctaCard']) : null,
            json['links'] ? json['links'].map((link: any) => Link.fromJSON(link)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        )
    }
}
