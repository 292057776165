import {urlBuilder} from "../../helpers/url-builder";

export interface ImageFormatOptions {
    ext: string
    url: string,
    hash: string,
    mime: string,
    name: string,
    path: string,
    size: number,
    width: number,
    height: number

}

export class ImageFormat {
    constructor(
        public readonly large: ImageFormatOptions | null,
        public readonly small: ImageFormatOptions| null,
        public readonly medium: ImageFormatOptions| null,
        public readonly thumbnail: ImageFormatOptions| null
    ) {}
}

export class ImageModel {
    constructor(
        public readonly name: string | null,
        public readonly alternativeText: string | null,
        public readonly caption: string | null,
        public readonly width: number | null,
        public readonly formats: ImageFormat | null,
        public readonly hash: string | null,
        public readonly ext: string | null,
        public readonly mime: string | null,
        public readonly size: number | null,
        public readonly url: string | null,
        public readonly previewUrl: string | null,
        public readonly height: number | null,
    ) {}

    get fullUrl(): string {
        return urlBuilder(this.url || '');
    }

    get fullUrlLg(): string {
        return urlBuilder(this.formats?.large?.url || '');
    }

    get fullUrlMd(): string {
        return urlBuilder(this.formats?.medium?.url || '');
    }

    get fullUrlSm(): string {
        return urlBuilder(this.formats?.small?.url || '');
    }

    get fullUrlThumbnail(): string {
        return urlBuilder(this.formats?.thumbnail?.url || '');
    }

    get alt(): string {
        return this.alternativeText || this.name || '';
    }

    static fromJSON(json: any) {
        return new ImageModel(
            json['name'],
            json['alternativeText'],
            json['caption'],
            json['width'],
            json['formats'],
            json['hash'],
            json['ext'],
            json['mime'],
            json['size'],
            json['url'],
            json['previewUrl'],
            json['height'],
        );
    }
}
