import {ImageModel} from "./image";
import {SocialButtonModel} from "./cta";

export class ContactFormModel {
    constructor(
        public readonly title: string | null,
        public readonly email: string | null,
        public readonly submitLabel: string | null,
        public readonly submittedTitle: string | null,
        public readonly formIcon: ImageModel | null,
        public readonly showForm: boolean | null,
        public readonly contacts: SocialButtonModel[] | null,
    ) {}

    static fromJSON(json: any) {
        return new ContactFormModel(
            json['title'],
            json['email'],
            json['submitLabel'],
            json['submittedTitle'],
            json['formIcon'] && json['formIcon']['icon'] ? ImageModel.fromJSON(json['formIcon']['icon']) : null,
            json['showForm'],
            json['contacts'] ? json['contacts'].map((button: any) => SocialButtonModel.fromJSON(button)) : [],
        )
    }
}