import {FormSuccessAction, FormSuccessActionTypes, IFormSuccessState} from "../types/form-success.type";
import {FormSuccessModel} from "../models/form-success";

const initialState: IFormSuccessState = {
    data: FormSuccessModel.fromJSON({}),
}

export const formSuccessReducer = (state: IFormSuccessState = initialState, action: FormSuccessAction): IFormSuccessState => {
    switch (action.type) {
        case FormSuccessActionTypes.FORM_SUCCESS_FETCH: {
            return { ...state, data: action.payload}
        }
        default:
            return state
    }
}
