import {CookiesAction, CookiesActionTypes, ICookiesState} from "../types/cookies.type";
import {CookiesPageModel} from "../models/cookies-page";

const initialState: ICookiesState = {
  data: CookiesPageModel.fromJSON({}),
}

export const cookiesReducer = (state: ICookiesState = initialState, action: CookiesAction): ICookiesState => {
  switch (action.type) {
    case CookiesActionTypes.COOKIES_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
