import {ImageModel} from "./image";
import {CtaBox} from "./cta";
import {SEOModel} from "./seo";

export class TranscarpathiaListModel {
    constructor(
        public readonly icon: ImageModel | null,
        public readonly text: string | null,
    ) {}

    static fromJSON(json: any) {
        return new TranscarpathiaListModel(
            json['icon'] && json['icon']['icon'] ? ImageModel.fromJSON(json['icon']['icon']) : null,
            json['text'],
        );
    }
}

export class MapCoordinate {
    constructor(
        public readonly title: string | null,
        public readonly x: string | null,
        public readonly y: string | null,
    ) {}

    static fromJSON(json: any) {
        return new MapCoordinate(
            json['title'],
            json['x'],
            json['y'],
        );
    }
}

export class MapPoints {
    constructor(
        public readonly label: string | null,
        public readonly coordinates: MapCoordinate | null,
        public readonly image: ImageModel | null,
    ) {}

    static fromJSON(json: any) {
        return new MapPoints(
            json['label'],
            json['coordinates'] ? MapCoordinate.fromJSON(json['coordinates']) : null,
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
        );
    }
}

export class MapCategories {
    constructor(
        public readonly label: string | null,
        public readonly icon: ImageModel | null,
        public readonly mapPoints: MapPoints[] | null
    ) {}

    static fromJSON(json: any) {
        return new MapCategories(
            json['label'],
            json['icon'] && json['icon']['icon'] ? ImageModel.fromJSON(json['icon']['icon']) : null,
            json['mapPoints'] ? json['mapPoints'].map((item: any) => MapPoints.fromJSON(item)) : [],
        );
    }
}

export class TranscarpathiaPageModel {
    constructor(
        public readonly mapTitle: string | null,
        public readonly imageLabel: string | null,
        public readonly list: TranscarpathiaListModel[] | null,
        public readonly ctaList: CtaBox[] | null,
        public readonly mapCategories: MapCategories[] | null,
        public readonly seo: SEOModel | null
    ) {}

    static fromJSON(json: any) {
        return new TranscarpathiaPageModel(
            json['mapTitle'],
            json['imageLabel'],
            json['list'] ? json['list'].map((item: any) => TranscarpathiaListModel.fromJSON(item)) : [],
            json['ctaList'] ? json['ctaList'].map((item: any) => CtaBox.fromJSON(item)) : [],
            json['mapCategories'] ? json['mapCategories'].map((item: any) => MapCategories.fromJSON(item)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null
        );
    }
}