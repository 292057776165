import {FormSuccessModel} from "../models/form-success";

export interface IFormSuccessState {
    data: FormSuccessModel,
}

export enum FormSuccessActionTypes {
    FORM_SUCCESS_FETCH = 'FORM_SUCCESS_FETCH',
    FORM_SUCCESS_FAILED = 'FORM_SUCCESS_FAILED',
}

interface FormSuccessFetch {
    type: FormSuccessActionTypes.FORM_SUCCESS_FETCH,
    payload: any
}

interface FormSuccessFailed {
    type: FormSuccessActionTypes.FORM_SUCCESS_FAILED,
    payload: any
}

export type FormSuccessAction = FormSuccessFetch | FormSuccessFailed

