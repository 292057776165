import {INewsState, NewsAction, NewsActionTypes} from "../types/news.type";

const initialState: INewsState = {
 // data: NewsPageModel.fromJSON({}),
  meta: null,
  news: null,
  loading: false,
  query: {},
}

export const newsReducer = (state: INewsState = initialState, action: NewsAction): INewsState => {
  switch (action.type) {
    case NewsActionTypes.NEWS_FETCH: {
      return { ...state, meta: action.payload.meta, news: action.payload.news }
    }
    case NewsActionTypes.NEWS_QUERY: {
      return { ...state, query: action.payload.query }
    }
    case NewsActionTypes.NEWS_SET_LOADING: {
      return { ...state, loading: action.payload.flag }
    }
    default:
      return state
  }
}
