import {AboutUsPageModel} from "../models/about-us-page";

export interface IAboutUsState {
  data: AboutUsPageModel,
}

export enum AboutUsActionTypes {
  ABOUT_US_FETCH = 'ABOUT_US_FETCH',
  ABOUT_US_FAILED = 'ABOUT_US_FAILED',
}

interface AboutUsFetch {
  type: AboutUsActionTypes.ABOUT_US_FETCH,
  payload: any
}

interface AboutUsFailed {
  type: AboutUsActionTypes.ABOUT_US_FAILED,
  payload: any
}

export type AboutUsAction = AboutUsFetch | AboutUsFailed

