import {BoardAction, BoardActionTypes, IBoardState} from "../types/board.type";
import {BoardPageModel} from "../models/board-page";

const initialState: IBoardState = {
  data: BoardPageModel.fromJSON({}),
}

export const boardReducer = (state: IBoardState = initialState, action: BoardAction): IBoardState => {
  switch (action.type) {
    case BoardActionTypes.BOARD_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
