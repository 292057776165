import {PrivacyPolicyPageModel} from "../models/privacy-policy-page";

export interface IPrivacyPolicyState {
  data: PrivacyPolicyPageModel,
}

export enum PrivacyPolicyActionTypes {
  PRIVACY_POLICY_FETCH = 'PRIVACY_POLICY_FETCH',
  PRIVACY_POLICY_FAILED = 'PRIVACY_POLICY_FAILED',
}

interface PrivacyPolicyFetch {
  type: PrivacyPolicyActionTypes.PRIVACY_POLICY_FETCH,
  payload: any
}

interface PrivacyPolicyFailed {
  type: PrivacyPolicyActionTypes.PRIVACY_POLICY_FAILED,
  payload: any
}

export type PrivacyPolicyAction = PrivacyPolicyFetch | PrivacyPolicyFailed

