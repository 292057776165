import {ImageModel} from "./image";
import {SEOModel} from "./seo";
import {LinkModel} from "./internal-link";

export class WorkLink {
    constructor(
        public readonly label: string | null,
        public readonly work: WorksPageWork | null,
    ) {}

    static fromJSON(json: any) {
        return new WorkLink(
            json['label'],
            json['work'] ? WorksPageWork.fromJSON(json['work']) : null,
        );
    }
}

export class List {
    constructor(
        public readonly text: string | null,
    ) {}

    static fromJSON(json: any) {
        return new List(
            json['text'],
        );
    }
}

export class InitiatorModel {
    constructor(
        public readonly description: string | null,
        public readonly image: ImageModel | null,
        public readonly link: LinkModel | null,
    ) {}

    static fromJSON(json: any) {
        return new InitiatorModel(
            json['description'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
            json['link'] ? LinkModel.fromJSON(json['link']) : null,
        );
    }
}


export class WorksPageWork {
    constructor(
        public readonly id: number | null,
        public readonly title: string | null,
        public readonly initiators: InitiatorModel[] | null,
        public readonly list: List[] | null,
        public readonly logo: ImageModel | null,
        public readonly slug: string | null
    ) {}

    static fromJSON(json: any) {
        return new WorksPageWork(
            json['id'],
            json['title'],
            json['initiators'] ? json['initiators'].map((initiator: any) => InitiatorModel.fromJSON(initiator)) : [],
            json['list'] ? json['list'].map((li: any) => List.fromJSON(li)) : [],
            json['logo'] ? ImageModel.fromJSON(json['logo']) : null,
            json['slug']
        );
    }
}

export class WorksPageModel {
    constructor(
        public readonly title: string | null,
        public readonly description: string | null,
        public readonly residentsLabel: string | null,
        public readonly works: WorkLink[] | null,
        public workTypes: string[] | null,
        public readonly seo: SEOModel | null,
        public readonly readMoreLabel: string | null,
    ) {}

    static fromJSON(json: any) {
        return new WorksPageModel(
            json['title'],
            json['description'],
            json['residentsLabel'],
            json['works'] ? json['works'].map((work: any) => WorkLink.fromJSON(work)) : [],
            null,
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
            json['readMoreLabel'],
        );
    }
}
