export class CategoryModel {
    constructor(
        public readonly label: string | null,
        public readonly category: string | null,
    ) {}

    static fromJSON(json: any) {
        return new CategoryModel(
            json['label'],
            json['category'] ? json['category']['slug'] : null,
        )
    }
}

export class CategoryLabelsModel {
    constructor(
        public readonly allCategoriesLabel: string | null,
        public readonly categories:  CategoryModel[] | null,
    ) {}

    static fromJSON(json: any) {
        return new CategoryLabelsModel(
            json['allCategoriesLabel'],
            json['categories'] ? json['categories'].map((category: any) => CategoryModel.fromJSON(category)) : [],
        );
    }
}
