import {ImageModel} from "./image";

export class ResidentLink {
    constructor(
        public readonly label: string | null,
        public readonly resident: ResidentModel | null,
    ) {}

    static fromJSON(json: any) {
        return new ResidentLink(
            json['label'],
            json['resident'] ? ResidentModel.fromJSON(json['resident']) : null,
        );
    }
}

export class ResidentModel {
    constructor(
       public readonly description: string | null,
       public readonly name: string | null,
       public readonly whiteLogo: ImageModel | null,
       public readonly logo: ImageModel| null,
    ) {}

    static fromJSON(json: any) {
        return new ResidentModel(
            json['description'],
            json['name'],
            json['whiteLogo'] ? ImageModel.fromJSON(json['whiteLogo']) : null,
            json['logo'] ? ImageModel.fromJSON(json['logo']) : null,
        );
    }
}
