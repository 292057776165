import {IMenuState, MenuAction, MenuActionTypes} from "../types/menu.type";
import {MenuModel} from "../models/menu";

const initialState: IMenuState = {
  data: MenuModel,
  error: null
}

export const menuReducer = (state: IMenuState = initialState, action: MenuAction): IMenuState => {
  switch (action.type) {
    case MenuActionTypes.MENU_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
