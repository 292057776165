import {
    IRegisterCompaniesState,
    RegisterCompaniesAction,
    RegisterCompaniesActionTypes
} from "../types/register-companies.type";
import {RegisterPage} from "../models/register-page";

const initialState: IRegisterCompaniesState = {
  data: RegisterPage,
}

export const registerCompanies = (state: IRegisterCompaniesState = initialState, action: RegisterCompaniesAction): IRegisterCompaniesState => {
  switch (action.type) {
    case RegisterCompaniesActionTypes.REGISTER_COMPANIES_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
