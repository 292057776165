import {HomeAction, HomeActionTypes, IHomeState} from "../types/home.type";
import {HomePageModel} from "../models/home-page";

const initialState: IHomeState = {
  data: HomePageModel.fromJSON({}),
  loaded: false
}

export const homeReducer = (state: IHomeState = initialState, action: HomeAction): IHomeState => {
  switch (action.type) {
    case HomeActionTypes.HOME_FETCH: {
      return { ...state, data: action.payload, loaded: true }
    }
    default:
      return state
  }
}
