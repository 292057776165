import {Link} from "./register-page";
import {Cta, SocialButtonModel} from "./cta";
import {InternalLink} from "./internal-link";
import {enumFromStringValue} from "../../utils/enum-from-string";

export class MenuSection {
    constructor(
        public readonly label: string | null,
        public readonly url: InternalLink | null,
        public readonly links: Link[] | null,
    ) {}

    static fromJSON(json: any) {
        return new MenuSection(
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
            json['links'] ? json['links'].map((link: any) => Link.fromJSON(link)) : [],
        );
    }
}


export class MenuLink {
    constructor(
        public readonly type: string | null,
        public readonly section: MenuSection | null,
        public readonly label: string | null,
        public readonly url: InternalLink | null,
    ) {}

    get title(): string {
        if (this.type == MenuLinkType.DROPDOWN) {
            return this.section?.label || this.label || '';
        }

        return this.label || '';
    }

    get link(): InternalLink | null {
        if (this.type == MenuLinkType.DROPDOWN) {
            return this.section?.url || this.url;
        }

        return this.url;
    }

    static fromJSON(json: any) {
        return new MenuLink(
            enumFromStringValue(MenuLinkType, json['__component']) || null,
            json['section'] ? MenuSection.fromJSON(json['section']) : null,
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
        );
    }
}

export class MenuModel {
    constructor(
        public readonly menuBtn: string | null,
        public readonly contactLabel: string | null,
        public readonly contactUrl: InternalLink | null,
        public readonly email: string | null,
        public readonly links: MenuLink[] | null,
        public readonly cta: Cta | null,
        public readonly socialButtons: SocialButtonModel[] | null,
    ) {}

    static fromJSON(json: any) {
        return new MenuModel(
            json['menuBtn'],
            json['contactLabel'],
            new InternalLink(json['contactUrl'] ? json['contactUrl']['url'] : null),
            json['email'],
            json['links'] ? json['links'].map((link: any) => MenuLink.fromJSON(link)) : [],
            json['cta'] ? Cta.fromJSON(json['cta']) : null,
            json['socialButtons'] ? json['socialButtons'].map((button: any) => SocialButtonModel.fromJSON(button)) : [],

        );
    }
}

export enum MenuLinkType {
    DROPDOWN = 'menu.dropdown',
    LINK = 'menu.link',
}
