import {RegisterPage} from "../models/register-page";

export interface IRegisterIE {
  id?: number;
  title: string;
}

export interface IRegisterIEState {
  data: RegisterPage,
}

export enum RegisterIEActionTypes {
  REGISTER_IE_FETCH = 'REGISTER_IE_FETCH',
  REGISTER_IE_FAILED = 'REGISTER_IE_FAILED',
}

interface RegisterIEFetch {
  type: RegisterIEActionTypes.REGISTER_IE_FETCH,
  payload: any
}

interface RegisterIEFailed {
  type: RegisterIEActionTypes.REGISTER_IE_FAILED,
  payload: any
}

export type RegisterIEAction = RegisterIEFetch | RegisterIEFailed

