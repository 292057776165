import {IMembersState, MembersAction, MembersActionTypes} from "../types/members.type";
import {MembersPageModel} from "../models/members-page";

const initialState: IMembersState = {
  data: MembersPageModel,
}

export const membersReducer = (state: IMembersState = initialState, action: MembersAction): IMembersState => {
  switch (action.type) {
    case MembersActionTypes.MEMBERS_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
