import {AboutUsAction, AboutUsActionTypes, IAboutUsState} from "../types/about-us.type";
import {AboutUsPageModel} from "../models/about-us-page";

const initialState: IAboutUsState = {
  data: AboutUsPageModel.fromJSON({}),
}

export const aboutUsReducer = (state: IAboutUsState = initialState, action: AboutUsAction): IAboutUsState => {
  switch (action.type) {
    case AboutUsActionTypes.ABOUT_US_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
