export { wrapRootElement } from "./wrapRootElement";

import ReactDOM from 'react-dom';
import "./src/styles/global.scss"

export function replaceHydrateFunction() {
    return (element:any, container:any, callback:any) => {
        ReactDOM.render(element, container, callback);
    };
}
