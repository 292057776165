import {SEOModel} from "./seo";
import {MenuLinkType} from "./menu";

export class DateModel {
    constructor(
        public readonly date: string | null
    ) {
    }

    get parsed(): Date | null {
        const date = new Date(this.date || '');

        return !isNaN(date.getTime()) ? date : null;
    }

    get formatted(): string {
        const date = this.parsed;
        if (date != null) {
            const monthIndex = date.getMonth();
            const month = (+monthIndex + 1).toString().padStart(2, "0")
            const year  = date.getFullYear();
            const day = date.getDate().toString().padStart(2, "0");

            return [day, month, year].join('.');
        } else {
            return '';
        }
    }

}
