import {urlBuilder} from "../../helpers/url-builder";

export class VideoModel {
    constructor(
        public readonly name: string | null,
        public readonly alternativeText: string | null,
        public readonly caption: string | null,
        public readonly width: string | null,
        public readonly height: string | null,
        public readonly formats: string | null,
        public readonly hash: string | null,
        public readonly ext: string | null,
        public readonly mime: string | null,
        public readonly size: number | null,
        public readonly url: string | null,
        public readonly previewUrl: string | null,
        public readonly provider: string | null,
        public readonly provider_metadata: string | null,
        public readonly createdAt: string | null,
        public readonly updatedAt: string | null,
    ) {}

    get fullUrl(): string {
        return urlBuilder(this.url || '');
    }

    static fromJSON(json: any) {
        return new VideoModel(
            json['name'],
            json['alternativeText'],
            json['caption'],
            json['width'],
            json['height'],
            json['formats'],
            json['hash'],
            json['ext'],
            json['mime'],
            json['size'],
            json['url'],
            json['previewUrl'],
            json['provider'],
            json['provider_metadata'],
            json['createdAt'],
            json['updatedAt'],
        );
    }
}