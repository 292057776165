import {TranscarpathiaPageModel} from "../models/transcarpathia-page";

export interface ITranscarpathiaState {
  data: TranscarpathiaPageModel,
}

export enum TranscarpathiaActionTypes {
  TRANSCARPATHIA_FETCH = 'TRANSCARPATHIA_FETCH',
  TRANSCARPATHIA_FAILED = 'TRANSCARPATHIA_FAILED',
}

interface TranscarpathiaFetch {
  type: TranscarpathiaActionTypes.TRANSCARPATHIA_FETCH,
  payload: any
}

interface TranscarpathiaFailed {
  type: TranscarpathiaActionTypes.TRANSCARPATHIA_FAILED,
  payload: any
}

export type TranscarpathiaAction = TranscarpathiaFetch | TranscarpathiaFailed

