import locales, {Locale, UA_LOCALE} from '../../config/locales';

const isBrowser = typeof window !== "undefined"
export default (): Locale => {
    if (!isBrowser) {
        return UA_LOCALE;
    }
    const url = window.location.href || document.URL;
    const locale = locales.find(item => {
        const path = item.path;
        if (path !== '' && (url.includes(`/${path}/`) || url.includes(`/${path}`))) {
            return true;
        } else {
            return false;
        }
    })

    return locale || UA_LOCALE;
};
