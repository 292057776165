import {CookiesPageModel} from "../models/cookies-page";

export interface ICookiesState {
  data: CookiesPageModel,
}

export enum CookiesActionTypes {
  COOKIES_FETCH = 'COOKIES_FETCH',
  COOKIES_FAILED = 'COOKIES_FAILED',
}

interface CookiesFetch {
  type: CookiesActionTypes.COOKIES_FETCH,
  payload: any
}

interface CookiesFailed {
  type: CookiesActionTypes.COOKIES_FAILED,
  payload: any
}

export type CookiesAction = CookiesFetch | CookiesFailed

