import {combineReducers} from "redux"
import {homeReducer} from "./home.reducer";
import {menuReducer} from "./menu.reducer";
import {worksReducer} from "./works.reducer";
import {partnersReducer} from "./partners.reducer";
import {membersReducer} from "./members.reducer";
import {registerIE} from "./register-ie.reducer";
import {registerCompanies} from "./register-companies.reducer";
import {registerPartners} from "./register-partners.reducer";
import {formServiceReducer} from "./form-service.reducer";
import {privacyPolicyReducer} from "./privacy-policy.reducer";
import {formSuccessReducer} from "./form-success.reducer";
import {notFoundReducer} from "./not-found.reducer";
import {cookiesReducer} from "./cookies.reducer";
import {boardReducer} from "./board.reducer";
import {newsReducer} from "./news.reducer";
import {categoryLabelsReducer} from "./category-labels.reducer";
import {newsPostReducer} from "./news-post.reducer";
import {transcarpathiaReducer} from "./transcarpathia.reducer";
import {aboutUsReducer} from "./about-us.reducer";
import {contactFormReducer} from "./contact-form.reducer";

export const rootReducer = combineReducers({
  homeStore: homeReducer,
  menuStore: menuReducer,
  worksStore: worksReducer,
  partnersStore: partnersReducer,
  membersStore: membersReducer,
  registerIEStore: registerIE,
  registerCompaniesStore: registerCompanies,
  registerPartnersStore: registerPartners,
  formService: formServiceReducer,
  privacyPolicyStore: privacyPolicyReducer,
  formSuccessStore: formSuccessReducer,
  notFoundStore: notFoundReducer,
  cookiesStore: cookiesReducer,
  boardStore: boardReducer,
  newsStore: newsReducer,
  categoryLabelsStore: categoryLabelsReducer,
  newsPostStore: newsPostReducer,
  transcarpathiaStore: transcarpathiaReducer,
  aboutUsStore: aboutUsReducer,
  contactFormStore: contactFormReducer,
})

export type RootState = ReturnType<typeof rootReducer>
