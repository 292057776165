import {NewsPostPageModel} from "../models/news-post-page";

export interface INewsPostState {
  data: NewsPostPageModel,
  loaded: boolean
}

export enum NewsPostActionTypes {
  NEWS_POST_FETCH = 'NEWS_POST_FETCH',
  NEWS_POST_FAILED = 'NEWS_POST_FAILED',
  NEWS_POST_SET_LOADED = 'NEWS_POST_SET_LOADED',
}

interface NewsPostFetch {
  type: NewsPostActionTypes.NEWS_POST_FETCH,
  payload: any
}

interface NewsPostFailed {
  type: NewsPostActionTypes.NEWS_POST_FAILED,
  payload: any
}

interface NewsPostSetLoading {
  type: NewsPostActionTypes.NEWS_POST_SET_LOADED,
  payload: boolean
}

export type NewsPostAction = NewsPostFetch | NewsPostFailed | NewsPostSetLoading

