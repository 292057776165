export type Route = {
    path: string,
    name: string,
    match?: string
}

export const HOME_ROUTE: Route = {
    path: 'index',
    name: 'home'
};
export const MEMBERS_ROUTE: Route = {
    path: 'members',
    name: 'members'
}
export const SIGN_UP_FOR_PARTNERS_ROUTE: Route = {
    path: 'sign-up-for-partners',
    name: 'sign-up-for-partners'
}
export const PARTNERS_ROUTE: Route = {
    path: 'partners',
    name: 'partners'
}
export const SIGN_UP_FOR_COMPANIES_ROUTE: Route = {
    path: 'sign-up-for-companies',
    name: 'sign-up-for-companies'
}
export const SIGN_UP_FOR_IE_ROUTE: Route = {
    path: 'sign-up-for-IE',
    name: 'sign-up-for-IE'
}
export const WORKSTREAMS_ROUTE: Route = {
    path: 'workstreams',
    name: 'workstreams'
}
export const PRIVACY_POLICY_ROUTE: Route = {
    path: 'privacy-policy',
    name: 'privacy-policy'
}
export const COOKIES_ROUTE: Route = {
    path: 'cookies',
    name: 'cookies'
}
export const NOT_FOUND_ROUTE: Route = {
    path: 'not-found',
    name: '404'
}
export const BOARD_ROUTE: Route = {
    path: 'board',
    name: 'board'
}
export const NEWS_ROUTE: Route = {
    path: 'news',
    name: 'news'
}
export const NEWS_CATEGORY_ROUTE: Route = {
    path: 'news/category/',
    name: 'news',
    match: 'news/category/:slug'
}
export const NEWS_POST_ROUTE: Route = {
    path: 'news/',
    name: 'news-post',
    match: 'news/:slug'
}
export const TRANSCARPATHIA_ROUTE: Route = {
    path: 'transcarpathia',
    name: 'transcarpathia'
}
export const ABOUT_US_ROUTE: Route = {
    path: 'about-us',
    name: 'about-us'
}
export const FAQ_ROUTE: Route = {
    path: 'FAQ',
    name: 'FAQ'
}


const routes: Route[] = [
    HOME_ROUTE,
    MEMBERS_ROUTE,
    SIGN_UP_FOR_PARTNERS_ROUTE,
    PARTNERS_ROUTE,
    SIGN_UP_FOR_COMPANIES_ROUTE,
    SIGN_UP_FOR_IE_ROUTE,
    WORKSTREAMS_ROUTE,
    PRIVACY_POLICY_ROUTE,
    COOKIES_ROUTE,
    NOT_FOUND_ROUTE,
    BOARD_ROUTE,
    NEWS_POST_ROUTE,
    NEWS_ROUTE,
    TRANSCARPATHIA_ROUTE,
    ABOUT_US_ROUTE,
    NEWS_CATEGORY_ROUTE,
    FAQ_ROUTE,
]

export default routes;
