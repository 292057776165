import {NotFoundPageModel} from "../models/not-found";

export interface INotFoundState {
    data: NotFoundPageModel,
}

export enum NotFoundActionTypes {
    NOT_FOUND_FETCH = 'NOT_FOUND_FETCH',
    NOT_FOUND_FAILED = 'NOT_FOUND_FAILED',
}

interface NotFoundFetch {
    type: NotFoundActionTypes.NOT_FOUND_FETCH,
    payload: any
}

interface NotFoundFailed {
    type: NotFoundActionTypes.NOT_FOUND_FAILED,
    payload: any
}

export type NotFoundAction = NotFoundFetch | NotFoundFailed

