import {QueryModel} from "../models/query";

export interface INewsState {
  meta: any,
  news: any,
  query: QueryModel,
  loading: boolean,
}

export enum NewsActionTypes {
  NEWS_FETCH = 'NEWS_FETCH',
  NEWS_FAILED = 'NEWS_FAILED',
  NEWS_QUERY = 'NEWS_QUERY',
  NEWS_SET_LOADING = 'NEWS_SET_LOADING',
}

interface NewsFetch {
  type: NewsActionTypes.NEWS_FETCH,
  payload: {
    meta: any,
    news: any
  }
}

interface NewsFailed {
  type: NewsActionTypes.NEWS_FAILED,
  payload: any
}

interface NewsQuery {
  type: NewsActionTypes.NEWS_QUERY,
  payload: {
    query: QueryModel
  }
}

interface NewsSetLoading {
  type: NewsActionTypes.NEWS_SET_LOADING,
  payload: {
    flag: boolean
  }
}

export type NewsAction = NewsFetch | NewsFailed | NewsQuery | NewsSetLoading;

