import {SocialButtonModel} from "./cta";

export class FormSuccessModel {
    constructor(
        public readonly title: string | null,
        public readonly subtitle: string | null,
        public readonly email: string | null,
        public readonly socialTitle: string | null,
        public readonly socials: SocialButtonModel[] | null,
    ) {}


    static fromJSON(json: any) {
        return new FormSuccessModel(
            json['title'],
            json['subtitle'],
            json['email'],
            json['socialTitle'],
            json['socials'] ? json['socials'].map((button: any) => SocialButtonModel.fromJSON(button)) : [],
        )
    }
}
