import {PartnersPageModel} from "../models/partners-page";

export interface IPartners {
  id?: number;
  title: string;
}

export interface IPartnersState {
  data: PartnersPageModel,
}

export enum PartnersActionTypes {
  PARTNERS_FETCH = 'PARTNERS_FETCH',
  PARTNERS_FAILED = 'PARTNERS_FAILED',
}

interface PartnersFetch {
  type: PartnersActionTypes.PARTNERS_FETCH,
  payload: any
}

interface PartnersFailed {
  type: PartnersActionTypes.PARTNERS_FAILED,
  payload: any
}

export type PartnersAction = PartnersFetch | PartnersFailed

