import {ContactFormAction, ContactFormActionTypes, IContactFormState} from "../types/contact-form.type";
import {ContactFormModel} from "../models/contact-form";

const initialState: IContactFormState = {
  data: ContactFormModel.fromJSON({}),
}

export const contactFormReducer = (state: IContactFormState = initialState, action: ContactFormAction): IContactFormState => {
  switch (action.type) {
    case ContactFormActionTypes.CONTACT_FORM_FETCH: {
      return { ...state, data: action.payload}
    }
    default:
      return state
  }
}
