import {ImageModel} from "./image";
import {InternalLink} from "./internal-link";
import {SEOModel} from "./seo";

export class Link {
    constructor(
        public readonly label: string | null,
        public readonly url: InternalLink | null,
    ) {}

    static fromJSON(json: any) {
        return new Link(
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
        );
    }
}

export class RegisterPage {
    constructor(
        public readonly title: string | null,
        public readonly formIcon: ImageModel | null,
        public readonly links: Link[] | null,
        public readonly seo: SEOModel | null,
        public readonly submitTitle: string | null
    ) {}

    static fromJSON(json: any) {
        return new RegisterPage(
            json['title'],
            json['formIcon']['icon'] ? ImageModel.fromJSON(json['formIcon']['icon']) : null,
            json['links'] ? json['links'].map((link: any) => Link.fromJSON(link)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
            json['submitTitle']
        );
    }
}
