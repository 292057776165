import {InternalLink, LinkModel} from "./internal-link";
import {SOCIAL_TYPES} from "../../components/common/button/SocialButtonList";

export class Cta {
    constructor(
        public readonly label: string | null,
        public readonly url: InternalLink | null,
    ) {}

    static fromJSON(json: any) {
        return new Cta(
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
        );
    }
}

export class CtaCard {
    constructor(
        public readonly description: string | null,
        public readonly label: string | null,
        public readonly url: InternalLink | null,
        public readonly mobileLabel: string | null,
    ) {}

    static fromJSON(json: any) {
        return new CtaCard(
            json['description'],
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
            json['mobileLabel'],
        );
    }
}

export class SocialButtonType {
    constructor(
        public readonly name: SOCIAL_TYPES | null,
    ) {}

    static fromJSON(json: any) {
        return new SocialButtonType(
            json['name'],
        )
    }
}

export class SocialButtonModel {
    constructor(
        public readonly type: SOCIAL_TYPES | null,
        public readonly url: string | null,
        public readonly internalUrl: InternalLink | null,
    ) {}

    static fromJSON(json: any) {
        return new SocialButtonModel(
            json['type'] ? json['type']['name'] : null,
            json['url'],
            new InternalLink(json['internalUrl'] ? json['internalUrl']['url'] : null),
        );
    }
}

export class CtaBox {
    constructor(
        public readonly description: string | null,
        public readonly label: string | null,
        public readonly link: LinkModel | null,
    ) {}

    static fromJSON(json: any) {
        return new CtaBox(
            json['description'],
            json['label'],
            json['link'] ? LinkModel.fromJSON(json['link']) : null,
        );
    }
}

export class CtaDoubleCard {
    constructor(
        public readonly title: string | null,
        public readonly cardLeft: CtaCard | null,
        public readonly cardRight: CtaCard | null,
    ) {}

    static fromJSON(json: any) {
        return new CtaDoubleCard(
            json['title'],
            json['cardLeft'] ? CtaCard.fromJSON(json['cardLeft']) : null,
            json['cardRight'] ? CtaCard.fromJSON(json['cardRight']) : null,
        );
    }
}