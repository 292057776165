import {NewsPostAction, NewsPostActionTypes, INewsPostState} from "../types/news-post.type";
import {NewsPostPageModel} from "../models/news-post-page";

const initialState: INewsPostState = {
  data: NewsPostPageModel.fromJSON({}),
  loaded: false
}

export const newsPostReducer = (state: INewsPostState = initialState, action: NewsPostAction): INewsPostState => {
  switch (action.type) {
    case NewsPostActionTypes.NEWS_POST_FETCH: {
      return { ...state, data: action.payload, loaded: true }
    }
    case NewsPostActionTypes.NEWS_POST_SET_LOADED: {
      return { ...state, loaded: action.payload }
    }
    default:
      return state
  }
}
