import currentLocale from "../../helpers/current-locale";
import {NewsPostModel} from "./news-post-page";
import router from "../../routes/router";

export class InternalLink {
    constructor(public readonly url: string | null) {
    }

    get link(): string {
        const current: any = currentLocale();
        return this.url ? `${current.path ? '/' + current.path : ''}${this.url}` : '#';
    }
}


export class LinkModel {
    constructor(
        public readonly internalUrl: InternalLink | null,
        public readonly url: string | null,
        public readonly newsPost: NewsPostModel | null,
    ) {
    }

    get link(): string | null {
        if (this.newsPost) {
            return this.getNewsUrl();
        } else if (this.internalUrl) {
            return this.internalUrl.link;
        } else if (this.url) {
            return this.url;
        } else {
            return null;
        }
    }

    getNewsUrl(): string {
        return router.to('news-post', {
            params: {
                slug: this.newsPost?.slug
            }
        })
    }

    static fromJSON(json: any) {
        return new LinkModel(
            json['internalUrl'] ? new InternalLink(json['internalUrl']['url']) : null,
            json['url'],
            json['newsPost'] ? NewsPostModel.fromJSON(json['newsPost']) : null,
        );
    }
}