import {enumFromStringValue} from "../../utils/enum-from-string";
import {ImageModel} from "./image";
import {DateModel} from "./date-model";
import {SEOModel} from "./seo";
import {VideoModel} from "./video";

export class NewsPostBody {
    constructor(
        public readonly type: NewsPostBodyType | null,
        public readonly label: string | null,
        public readonly url: string | null,
        public readonly subtitle: string | null,
        public readonly image: ImageModel | null,
        public readonly text: string | null,
        public readonly title: string | null,
        public readonly slug?: string | null,
        public readonly images?: ImageModel[] | null,
        public readonly videoUrl?: string | null,
        public readonly video?: VideoModel | null,
    ) {}

    get button(): {label: string | null, url: string | null} {
        return {
            label: this.label,
            url: this.url
        }
    }

    get imageFullSize() {
        return {
            image: this.image,
            subtitle: this.subtitle
        }
    }

    static fromJSON(json: any) {
        return new NewsPostBody(
            enumFromStringValue(NewsPostBodyType, json['__component']) || null,
            json['label'],
            json['url'],
            json['subtitle'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
            json['text'],
            json['title'],
            json['slug'],
            json['images'] ? json['images'].map((img: any) => ImageModel.fromJSON(img)) : [],
            json['videoUrl'],
            json['video'] ? VideoModel.fromJSON(json['video']) : null,
        );
    }
}

export class NewsPostModel {
    constructor(
        public readonly title: string | null,
        public readonly slug: string | null,
        public readonly category: string | null,
        public readonly image: ImageModel | null,
        public readonly createdDate: DateModel | null,
        public readonly createdAt: DateModel | null,
        public readonly body: NewsPostBody[] | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new NewsPostModel(
            json['title'],
            json['slug'],
            json['category'] ? json['category']['slug'] : null,
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
            new DateModel(json['createdDate']),
            new DateModel(json['createdAt']),
            json['body'] ? json['body'].map((component: any) => NewsPostBody.fromJSON(component)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        );
    }
}

export class NewsPostPageModel {
    constructor(
        public readonly title: string | null,
        public readonly nextArticlesLabel: string | null,
        public readonly post: NewsPostModel | null,
    ) {}

    static fromJSON(json: any) {
        return new NewsPostPageModel(
            json['title'],
            json['nextArticlesLabel'],
            json['post'] ? NewsPostModel.fromJSON(json['post']) : null,
        );
    }
}

export enum NewsPostBodyType {
    TEXT = 'news.text',
    IMAGES_GRID = 'news.images-grid',
    IMAGE_FULL_SIZE = 'news.image-full-size',
    BUTTON = 'news.button',
    VIDEO = 'news.video',
}
