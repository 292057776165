import {CategoryLabelsModel} from "../models/category-labels";

export interface ICategoryLabelsState {
  data: CategoryLabelsModel,
}

export enum CategoryLabelsActionTypes {
  CATEGORY_LABELS_FETCH = 'CATEGORY_LABELS_FETCH',
  CATEGORY_LABELS_FAILED = 'CATEGORY_LABELS_FAILED',
}

interface CategoryLabelsFetch {
  type: CategoryLabelsActionTypes.CATEGORY_LABELS_FETCH,
  payload: CategoryLabelsModel
}

interface CategoryLabelsFailed {
  type: CategoryLabelsActionTypes.CATEGORY_LABELS_FAILED,
  payload: any
}


export type CategoryLabelsAction = CategoryLabelsFetch | CategoryLabelsFailed;

