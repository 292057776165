import {Cta, CtaDoubleCard, SocialButtonModel} from "./cta";
import {ImageModel} from "./image";
import {ResidentLink} from "./resident";
import {PartnerLink} from "./partner";
import {InternalLink} from "./internal-link";
import {SEOModel} from "./seo";

export class StatisticsModel {
    constructor(
        public readonly total: string | null,
        public readonly label: string | null,
        public readonly url: InternalLink | null,
    ) {}

    static fromJSON(json: any) {
        return new StatisticsModel(
            json['total'],
            json['label'],
            new InternalLink(json['url'] ? json['url']['url'] : null),
        )
    }
}

export class AboutUsCardListModel {
    constructor(
        public readonly title: string | null,
        public readonly description: string | null,
    ){}

    static fromJSON(json: any) {
        return new AboutUsCardListModel(
            json['title'],
            json['description'],
        )}
}

export class AboutUsImgCardModel {
    constructor(
        public readonly title: string | null,
        public readonly description: string | null,
        public readonly image: ImageModel | null,
        public readonly cta: Cta | null,
    ) {}

    static fromJSON(json: any) {
        return new AboutUsImgCardModel(
            json['title'],
            json['description'],
            json['image'] ? ImageModel.fromJSON(json['image']) : null,
            json['cta'] ? Cta.fromJSON(json['cta']) : null,
        )
    }
}

export class AboutUsGridsModel {
    constructor(
        public readonly title: string | null,
        public readonly subtitle: string | null,
        public readonly residentsLabel: string | null,
        public readonly residentsGrid: ResidentLink[] | null,
        public readonly residentsCta: Cta | null,
        public readonly partnersLabel: string | null,
        public readonly partnersGrid: PartnerLink[] | null,
        public readonly partnersCta: Cta | null,
    ) {}

    static fromJSON(json: any) {
        return new AboutUsGridsModel(
            json['title'],
            json['subtitle'],
            json['residentsLabel'],
            json['residentsGrid'] ? json['residentsGrid'].map((resident: any) => ResidentLink.fromJSON(resident)) : [],
            json['residentsCta'] ? Cta.fromJSON(json['residentsCta']) : null,
            json['partnersLabel'],
            json['partnersGrid'] ? json['partnersGrid'].map((partner: any) => PartnerLink.fromJSON(partner)) : [],
            json['partnersCta'] ? Cta.fromJSON(json['partnersCta']) : null,
        )
    }
}

export class AboutUsPageModel {
    constructor(
        public readonly title: string | null,
        public readonly description: string | null,
        public readonly subDescription: string | null,
        public readonly cta: Cta | null,
        public readonly statistics: StatisticsModel[] | null,
        public readonly cardTitle: string | null,
        public readonly cardList: AboutUsCardListModel[] | null,
        public readonly ctaImg: AboutUsImgCardModel | null,
        public readonly boardName: string | null,
        public readonly boardDescription: string | null,
        public readonly boardImage: ImageModel | null,
        public readonly ctaCard: CtaDoubleCard | null,
        public readonly grids: AboutUsGridsModel | null,
        public readonly socials: SocialButtonModel[] | null,
        public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new AboutUsPageModel(
            json['title'],
            json['description'],
            json['subDescription'],
            json['cta'] ? Cta.fromJSON(json['cta']) : null,
            json['statistics'] ? json['statistics'].map((item: any) => StatisticsModel.fromJSON(item)) : [],
            json['cardTitle'],
            json['cardList'] ? json['cardList'].map((item: any) => AboutUsCardListModel.fromJSON(item)) : [],
            json['ctaImg'] ? AboutUsImgCardModel.fromJSON(json['ctaImg']) : null,
            json['boardName'],
            json['boardDescription'],
            json['boardImage'] ? ImageModel.fromJSON(json['boardImage']) : null,
            json['ctaCard'] ? CtaDoubleCard.fromJSON(json['ctaCard']) : null,
            json['grids'] ? AboutUsGridsModel.fromJSON(json['grids']) : null,
            json['socials'] ? json['socials'].map((button: any) => SocialButtonModel.fromJSON(button)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        )
    }
}
