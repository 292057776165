import {INotFoundState, NotFoundAction, NotFoundActionTypes} from "../types/not-found.types";
import {NotFoundPageModel} from "../models/not-found";

const initialState: INotFoundState = {
    data: NotFoundPageModel.fromJSON({}),
}

export const notFoundReducer = (state: INotFoundState = initialState, action: NotFoundAction): INotFoundState => {
    switch (action.type) {
        case NotFoundActionTypes.NOT_FOUND_FETCH: {
            return { ...state, data: action.payload}
        }
        default:
            return state
    }
}
