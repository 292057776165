import {CategoryLabelsAction, CategoryLabelsActionTypes, ICategoryLabelsState} from "../types/category-labels.type";
import {CategoryLabelsModel} from "../models/category-labels";

const initialState: ICategoryLabelsState = {
  data: CategoryLabelsModel.fromJSON({}),
}

export const categoryLabelsReducer = (state: ICategoryLabelsState = initialState, action: CategoryLabelsAction): ICategoryLabelsState => {
  switch (action.type) {
    case CategoryLabelsActionTypes.CATEGORY_LABELS_FETCH: {
      return { ...state, data: action.payload }
    }
    default:
      return state
  }
}
