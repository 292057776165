import {MenuModel} from "../models/menu";

export interface IMenu {
  id?: number;
  images?: any;
  title: string;
}

export interface IMenuState {
  data: MenuModel,
  error: any
}

export enum MenuActionTypes {
  MENU_FETCH = 'MENU_FETCH',
  MENU_FAILED = 'MENU_FAILED',
}

interface MenuFetch {
  type: MenuActionTypes.MENU_FETCH,
  payload: any
}

interface MenuFailed {
  type: MenuActionTypes.MENU_FAILED,
  payload: any
}

export type MenuAction = MenuFetch | MenuFailed

